// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-course-template-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/templates/courseTemplate.js" /* webpackChunkName: "component---src-templates-course-template-js" */),
  "component---src-templates-video-template-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/templates/videoTemplate.js" /* webpackChunkName: "component---src-templates-video-template-js" */),
  "component---src-templates-lesson-template-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/templates/lessonTemplate.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */),
  "component---src-pages-callback-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-course-list-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/pages/course-list.js" /* webpackChunkName: "component---src-pages-course-list-js" */),
  "component---src-pages-index-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/Users/ebeazer/Projects/Able Home Care/Learn/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/ebeazer/Projects/Able Home Care/Learn/.cache/data.json")

